.footer {
  padding: 2rem;
  margin-top: 2rem;
  position: relative;
  background: whitesmoke;
}

a {
  text-decoration: none;
  color: inherit;
}

.footer img {
  opacity: 0.1;
  position: absolute;
  object-fit: contain;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.footer-content {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  max-width: 1140px;
  margin: 0 auto;
  
}

.footer-section {
  width: 300px;
  margin-bottom: 30px;
}

.footer-section h2 {
  font-size: large;
  margin-bottom: 20px;
}

.footer-section p {
  font-size: large;
}

.contact span {
  display: block;
  margin-bottom: 10px;
}

.links ul {
  list-style: none;
  padding: 0;
}

.links li {
  margin-bottom: 10px;
}

.links a {
  text-decoration: none;
  font-size: large;
}

.contact-form input[type="email"],
.contact-form textarea {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 0px !important;
}

.contact-form textarea {
  resize: none;
}

.footerLogo {
  display: none;
}

.footer-bottom {
  text-align: center;
  margin-top: 30px;
  align-items: center;
  max-width: 1140px;
  margin: 0 auto;
}

.contact-form a {
  color: inherit;
}

/***************************************** Media Qurey ***********************************/

@media only screen and (max-width: 1000px) {
  .footer-section{
    flex: 1;
  }
  .footer-content{
    gap: 2rem;
  }
}

@media only screen and (max-width: 600px) {
  .footer {
    padding: 1rem;
  }
  .footer-section {
    width: 100%;
    flex: auto;
    margin-bottom: 0;
  }
  .footer-content{
    gap: 0;
  }

  .contact-form input[type="email"],
  .contact-form textarea {
    width: 100%;
    margin-bottom: 10px;
    padding: 0;
    padding-bottom: 0rem;
    font-size: large;
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-left: 4px;
  }
  .about-btn-m {
    margin-left: 0 !important;
  }

  .footer img {
    width: inherit;
  }
}

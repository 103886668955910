.auto-align{
    align-items: center;
    text-align: center;
}

.service-cards-container{
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.service-cards{
    display: flex;
    flex-wrap: wrap;
    padding: 1rem;
    padding-top: 0;
    padding-bottom: 0;
    justify-content: center;
    flex-direction: row;
    gap: 1rem;
    max-width: 1140px;
}

.service-card-info{
    display: flex;
    flex-direction: column;
    border: 2px solid lightblue;
    align-items: center;
    width: 250px;
    border-radius: 1rem;
    padding: 30px 45px 20px;
    background: linear-gradient(to bottom, #edf3fa, #fff, #edf3fa);
    border-radius: 20px;
    margin-bottom: 30px;
    transition: box-shadow .7s ease;
    gap: 1.5rem;
    cursor: default;
    justify-content: space-between;

}

.service-card-info:hover{
    box-shadow: 0 7px 64px 0 rgba(0, 0, 0, .15);
}

.card-img-container{
    width: 40%;
    height: 30%;
}

.card-img-container img,svg{
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.card-title{
    font-size: 24px;
    line-height: 1.2em;
    font-weight: 750;
    width: 100%;
    text-align: center;
}

.card-description{
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    height: 30%;
}

.card-btn{
    display: flex;
    width: 100%;
}

.card-btn button{
    font-size: small !important;

}



/* Responsive Screen */

@media (max-width: 768px) {
    .service-cards{
        flex-direction: column;
        padding: 1rem;
    }

    .service-card-info{
        margin: 0;
        width: auto;
    }
    .card-img-container{
        width: 30%;
    }
}


.load-more-button {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
  
  .load-more-button button {
    background-color: blue; /* Green background */
    font-weight: bolder;
    color: white; /* White text */
    padding: 12px 24px; /* Button padding */
    border: none;
    border-radius: 4rem;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
  }
  
  .load-more-button button:hover {
    background-color: #45a049; /* Slightly darker green on hover */
    transform: scale(1.05); /* Slight zoom-in effect */
  }
  
  .load-more-button button:active {
    background-color: #388e3c; /* Even darker when clicked */
    transform: scale(1); /* Reset zoom when clicked */
  }
  

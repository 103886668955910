@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap");

/* Header Container */
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 40px;
  background-color: #ffffff;
  position: relative;
  z-index: 10;
  font-family: "Poppins", sans-serif;
}

/* Logo Styles */
.logo {
  display: flex;
  align-items: center;
  font-size: 1.8rem;
  font-weight: 900;
  color: #000;
}

.logo img {
  height: 50px;
  margin-right: 12px;
}

/* Navigation Styles */
.nav {
  display: flex;
  align-items: center;
  gap: 30px;
}

/* Navigation List */
.nav ul {
  display: flex;
  list-style: none;
  gap: 30px;
}

.nav a {
  text-decoration: none;
  color: #000;
  font-size: 1rem;
  font-weight: 500;
  padding: 10px 15px;
  transition: color 0.3s ease, transform 0.2s ease;
  position: relative;
  display: flex;
  align-items: center;
}

.nav a,
.nav-icons button {
  font-family: "Poppins", sans-serif; /* Poppins font for all relevant text */
  font-weight: 500; /* Adjust font weight as desired */
}

/* Container for the dot grid */
.header-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 30px; /* Adjust size */
  height: 30px;
}

/* Grid layout for the dots */
.dot-grid {
  display: grid;
  grid-template-columns: repeat(3, 8px); /* Creates 3 columns */
  grid-gap: 4px; /* Space between dots */
}

/* Styling each dot */
.dot {
  width: 8px; /* Dot size */
  height: 8px;
  background-color: #333; /* Default color */
  border-radius: 50%;
  transition: background-color 0.3s;
}

.dot:nth-child(2),
.dot:nth-child(5),
.dot:nth-child(8) {
  background-color: #2979ff; /* Color the center column dots blue */
}

/* Add hover effect */
.header-icon:hover .dot {
  background-color: #555; /* Changes color on hover, adjust if needed */
}

.header-icon:hover .dot:nth-child(2),
.header-icon:hover .dot:nth-child(5),
.header-icon:hover .dot:nth-child(8) {
  background-color: #2979ff; /* Keep the center dots blue on hover */
}

/* Add Down Outline Arrow Icon */
.nav .req-dropdown a::after {
  content: "▾"; /* Outline arrow icon */
  font-size: 0.7rem;
  margin-left: 5px;
  color: #000;
  transition: transform 0.3s ease;
}

.nav .no-dropdown a::after {
  content: "" !important;
  font-size: 0.7rem;
  margin-left: 5px;
  color: #000;
  transition: transform 0.3s ease;
}

.dropdown:hover a::after {
  transform: rotate(180deg);
}

.nav a:hover {
  color: #1e90ff; /* Customize hover color */
}

/* Dropdown Menu */
.dropdown {
  position: relative;
}

.dropdown-content {
  display: none;
  position: absolute;
  top: 100%; /* Positioned directly under the parent link */
  left: 0;
  background-color: #fff;
  border: 1px solid #ddd;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  min-width: 180px;
  z-index: 100;
  padding-top: 10px; /* Adds top spacing */
}

/* Ensures the dropdown stays open when hovering */
.dropdown:hover .dropdown-content,
.dropdown-content:hover {
  display: block;
}

.dropdown-content a {
  display: block;
  padding: 12px 20px;
  color: #000;
  font-size: 1rem;
  text-decoration: none;
  transition: background 0.3s ease;
}

/* Icons Styling */
.nav-icons {
  display: flex;
  gap: 20px;
}

.nav-icons button {
  background: none;
  border: none;
  font-size: 1.4rem;
  cursor: pointer;
  color: #333;
  transition: color 0.3s ease;
}

.nav-icons button:hover {
  color: #1e90ff;
}

/* Mobile Menu Icon */
.mobile-menu-icon {
  display: none;
  font-size: 2rem;
  background: none;
  border: none;
  cursor: pointer;
}

/* Mobile Responsive Styles */
@media (max-width: 1100px) {
  .nav {
    display: none;
  }

  .nav-mobile {
    display: flex;
    flex-direction: column;
    gap: 15px;
    position: absolute;
    top: 70px;
    right: 20px;
    background-color: #ffffff;
    padding: 20px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    z-index: 20;
  }

  .nav-mobile {
    flex-direction: column;
    gap: 10px;
  }

  a {
    text-decoration: none;
    color: inherit;
  }

  .nav-mobile .dropdown-content {
    position: relative;
    box-shadow: none;
    border: none;
  }

  .mobile-menu-icon {
    display: block;
    padding: 0;
  }
  .header {
    padding: 1rem;
  }
}

.privacyContainer {
  padding: 2rem;
}

.privacyContainer a {
  color: inherit;
}

/***************************************** Media Qurey ***********************************/

@media only screen and (max-width: 600px) {
  .privacyContainer {
    padding: 1rem;
    padding-bottom: 2rem;
  }
}

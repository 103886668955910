.heroConatiner {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: baseline;
}

.why-choose-us-samller-text {
  max-width: 100%;
  text-align: center;
}

.hero-section {
  display: flex;
  max-width: 1440px;
  justify-content: center;
  gap: 2rem;
  align-items: center;
  padding: 5rem;
  padding-top: 0;
}

.hero-left-content {
  display: flex;
  flex-direction: column;
  width: 50%;
  gap: 1rem;
}

.hero-left-content h2 {
  font-size: xxx-large;
  margin: 0;
}
.hero-left-content p {
  margin: 0;
}
.hero-title {
  color: #0b70e1;
  font-size: x-large;
  font-weight: 600;
}
.technology-text {
  color: #0b70e1;
}

.itsolution-text {
  color: #02388f;
}

.hero-right-content {
  width: 50%;
  height: fit-content;
}

.hero-right-content img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
/* why choose us css */

.container-why-choose-us {
  width: 100%;
  display: flex;
  justify-content: center;
}

.why-choose-us {
  display: flex;
  gap: 2rem;
  justify-content: center;
  align-items: center;
  max-width: 1440px;
  padding-top: 0;
  padding: 5rem;
  padding-top: 0;
}
.why-choose-us-left {
  width: 50%;
}
.why-choose-us-left img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.why-choose-us-right {
  display: flex;
  flex-direction: column;
  width: 50%;
  gap: 2rem;
  align-items: center;
}
.why-choose-us-imgtxt {
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.why-choose-us-img {
  width: 60px;
}

.why-choose-us-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.why-choose-us-title {
  font-size: larger;
  display: flex;
  align-items: center;
  gap: 1rem;
  color: #0b70e1;
  width: 100%;
  justify-content: center;
}

.horizontal-slash {
  display: block;
  width: 10%;
  height: 5px;
  background-color: #02388f;
  border-radius: 5px;
}

.why-choose-us-bigger-text {
  font-size: xx-large;
  font-weight: 600;
  text-align: center;
}

.why-choose-us-smaller-text {
  font-weight: 600;
}
.why-choose-us-svgtxt {
  font-size: x-large;
  font-weight: 500;
  margin: 0;
}

.why-choose-us-svgtxt-description {
  margin: 0;
}

.why-choose-us-svgtxt,
.why-choose-us-svgtxt-description {
  max-width: 300px;
  text-align: left;
}
.why-choose-us-imgtxtbox {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 100%;
}

/* Expertise Section */

.expertise-container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.expertise {
  max-width: 1440px;
  padding: 5rem;
  padding-top: 0;
  flex-direction: column;
  display: flex;
  gap: 2rem;
}

.flip-card {
  background-color: transparent;
  width: 200px;
  height: 200px;
  perspective: 1000px; /* Perspective gives a 3D effect */
}

.flip-card h2 {
  margin: 0;
  margin-bottom: 10px;
  padding-left: 1rem;
  padding-right: 1rem;
  font-size: medium;
}

.flip-card p {
  margin: 0;
  font-size: smaller;
  padding: 1rem;
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

.flip-card-front,
.flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden; /* Safari */
  backface-visibility: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  color: white;
  cursor: default;
}

.flip-card-front {
  background: #0b70e1;
}

.flip-card-back {
  background-image: linear-gradient(90deg, #03228f 0%, #0b70e1 100%);

  transform: rotateY(180deg);
}
.expertise-card-container {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  justify-content: center;
}

.flip-card-front img {
  width: 50%;
  height: 50%;
  padding: 1rem;
}

.ourClientsContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #f0f4f9;
}

.ourClients {
  max-width: 1440px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 5rem;
}

.section-title-global {
  font-size: xx-large;
  font-weight: 600;
  display: flex;
  width: 100%;
  align-items: center;
  gap: 1rem;
  justify-content: center;
  margin-bottom: 2rem;
}

.patners-content {
  display: flex;
  flex-wrap: wrap;
  gap: 4rem;
  align-items: center;
  justify-content: center;
}

.partnerImgContainer {
  width: 30px;
  height: 90px;
  flex: 1 0 20%;
}

.partnerImgContainer img,
svg {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.WhyClientsLoveUsContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.WhyClientsLoveUs {
  max-width: 1140px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 5rem;
}

.container-whyclientsloveus {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  width: 100%;
  margin-top: 2rem;
  gap: 3rem;
}

.section-whyclientsloveus {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  background: lightblue;
  border-radius: 1rem;
  padding: 1rem;
  justify-content: center;
  height: 200px;
  flex: 1 0 21%;
}

.section-whyclientsloveus:hover {
  background: lightgreen;
  -webkit-transition: background-color 100ms linear;
  -ms-transition: background-color 100ms linear;
  transition: background-color 100ms linear;
}

.container-whyclientsloveus img,
.container-whyclientsloveus svg {
  position: absolute;
  top: -50%;
  left: -5%;
  width: 70px;
  height: 100%;
  object-fit: contain;
}

/* CSS for Responsive Screen */
@media only screen and (max-width: 1100px) {
  .container-whyclientsloveus img,
  .container-whyclientsloveus svg {
    position: static;
  }
  .section-whyclientsloveus {
    height: auto;
  }
}
@media (max-width: 1110px) {
  .hero-left-content h2 {
    font-size: xx-large;
  }
}

@media (max-width: 768px) {
  .hero-left-content h2 {
    font-size: smaller;
  }
  .hero-left-content,
  .hero-right-content {
    width: 100%;
  }

  .why-choose-us-left {
    width: 100%;
  }
  .ourClients {
    padding: 1rem;
  }

  .hero-left-content {
    align-items: center;
    text-align: center;
  }
  .hero-section {
    flex-direction: column-reverse;
    padding: 1rem;
    justify-content: center;
    align-items: center;
  }

  .why-choose-us {
    flex-direction: column;
    padding: 1rem;
    text-align: center;
  }

  .why-choose-us-title {
    width: 100%;
    justify-content: center;
  }

  .why-choose-us-right {
    width: 100%;
    align-items: center;
  }

  .expertise {
    padding: 1rem;
  }
  .flip-card {
    flex: 1 0 40%;
    font-size: small;
  }
  .flip-card h2 {
    font-size: small;
  }
  .patners-content {
    gap: 1rem;
  }
  .partnerImgContainer {
    flex: 1 0 40%;
    width: 50px;
    height: 50px;
  }
  .WhyClientsLoveUs {
    padding: 1rem;
  }
  .container-whyclientsloveus {
    flex-direction: column;
    gap: 1rem;
    margin-top: 0;
  }
  .section-title-global,
  .why-choose-us-bigger-text {
    font-size: larger;
  }

  .why-choose-us-samller-text {
    max-width: 100%;
    text-align: center;
  }

  .container-whyclientsloveus img,
  .container-whyclientsloveus svg {
    width: 15%;
    top: -60%;
  }
}

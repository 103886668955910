.aboutContainer,
.whyus-container {
  width: 100%;
  display: flex;
  justify-content: center;
}

h1 {
  text-align: left;
}

.aboutChild,
.whyus-child-container {
  max-width: 1440px;
  display: flex;
  justify-content: center;
  padding: 5rem;
  padding-top: 1rem;
  gap: 2rem;
  align-items: center;
}

.about-hero-left,
.whyus-left {
  width: 50%;
  text-align: justify;
}
.l-align {
  justify-content: left;
}
.about-hero-right,
.whyus-right {
  width: 50%;
}

.about-hero-right img,
.whyus-right img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.h2-headings-whyus {
  position: relative;
  display: flex;
  gap: 1rem;
  align-items: center;
}

.h2-headings-whyus img {
  position: relative;
  left: -15%;
  width: 235px;
  height: 100%;
  transform: rotate(45deg);
}

/* General styling for all h2 elements inside the h2-headings-whyus container */
.h2-headings-whyus h2 {
  font-size: x-large; /* Adjust the font size as needed */
  color: #000; /* Text color */
}

/* Apply specific margin-left to different h2 elements */

/* First h2 (Discovery) */
.h2-headings-whyus h2:nth-child(1) {
  margin-left: 1rem;
}

/* Second h2 (Planning) */
.h2-headings-whyus h2:nth-child(2) {
  margin-left: 4rem;
}

/* Third h2 (Execute) */
.h2-headings-whyus h2:nth-child(3) {
  margin-left: 8rem;
}

/* Fourth h2 (Deliver) */
.h2-headings-whyus h2:nth-child(4) {
  margin-left: 12rem;
}

@media (max-width: 768px) {
  .aboutChild,
  .whyus-child-container {
    flex-direction: column;
    padding: 1rem;
  }
  .about-hero-left,
  .whyus-left {
    width: 100%;
  }

  .about-hero-right,
  .whyus-right {
    width: 100%;
  }

  .aboutChild h1 {
    text-align: left;
  }
  .whyus-child-container h1 {
    text-align: left;
  }

  .l-align {
    justify-content: center;
  }
}

@media (max-width: 1060px) {
  .h2-headings-whyus img {
    display: none;
  }
}

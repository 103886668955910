/* src/components/ApplyModal.css */
.modal {
    position: fixed;
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    transform: translate(-50%, -50%);
    background: white;
    padding: 20px;
    border-radius: 8px;
    width: 80%;
    max-width: 500px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.75);
  }
  
  form {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  form label {
    display: flex;
    flex-direction: column;
  }

  .close-button {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }

  .close-button {
    background-color: blue; /* Green background */
    font-weight: bolder;
    color: white; /* White text */
    padding: 12px 24px; /* Button padding */
    border: none;
    border-radius: 4rem;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
  }
  
  .close-button:hover {
    background-color: #45a049; /* Slightly darker green on hover */
    transform: scale(1.05); /* Slight zoom-in effect */
  }
  
  .load-more-button button:active {
    background-color: #388e3c; /* Even darker when clicked */
    transform: scale(1); /* Reset zoom when clicked */
  }
  
  input[type="text" i] {
    padding-block: 1px;
    padding-inline: 10px;
    height: 1.5rem !important;
    font-size: large;
    border-radius: 1rem;
}

input[type="email" i] {
  padding-block: 1px;
  padding-inline: 10px;
  height: 1.5rem !important;
  font-size: large;
  border-radius: 1rem;
}



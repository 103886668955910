.button-global {
  width: fit-content;
  padding: 14px 40px;
  background-color: transparent;
  background-image: linear-gradient(220deg, #03228f 0%, #0b70e1 79%);
  border-radius: 30px;
  color: white;
  position: relative;
  overflow: hidden;
  border: none;
  cursor: pointer;
  outline: none;
  transition: background 0.3s ease;
  font-weight: 600;
  font-size: large;
}

.ripple-button::after {
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
}

.ripple {
  position: absolute;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.4);
  transform: scale(0);
  animation: ripple-animation 0.6s linear;
  pointer-events: none;
  opacity: 0.7; /* Adjust opacity for desired visual effect */
}

@keyframes ripple-animation {
  0% {
    transform: scale(0);
    opacity: 0.7;
  }
  100% {
    transform: scale(4);
    opacity: 0;
  }
}

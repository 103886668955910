.formBoxConatiner{
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.formboxparent{
    display: flex;
    width: 100%;
    justify-content: center;
    
}

.right-from-box{
    width: 50%;
}

.right-from-box img{
    width: 100%;
}

.career-container{
    width: 100%;
    display: flex;
    justify-content: center;
}

.career-container img{
    width: 30%;
}

.container{
    display: flex;
    flex-direction: column;
    max-width: 1440px;
    padding: 5rem;

}

.form-child{
    gap: 2rem;
    padding: 5rem;
    max-width: 1440px;
    display: flex;
    justify-content: center;
    align-items: center;
}


.formBoxConatiner textarea{
    resize: none;
    height: 6rem;
    width: 100%;
    border-radius: 1rem;
    padding: 10px;
}

.AboutUsFormInputBox{
    display: flex;
    flex-direction: column;
    gap: .5rem;
}
.labelAboutUs{
    margin-top: .5rem;
    font-size: large;
}
.formBoxConatiner input:not([type="file" i], [type="image" i], [type="checkbox" i], [type="radio" i]) {
    width: 100%;
    height: 2rem !important;
    border-radius: 1rem;
}

.formBoxConatiner input,textarea
{
    font-size:large;
}

.about-btn{
    font-size:medium;
    border: 1px solid white;
    width: fit-content;
    padding: 1rem;
    padding-top: .5rem;
    padding-bottom: .5rem;
    margin-top: 1rem;
    cursor: pointer;
    background: black;
    color: white;
}
.thankyouBox{
    width: 100%;
    height: 100%;
    color: white;
    position: absolute;
    backdrop-filter: blur(12px);
    background: rgba(0, 0, 0, .8);
    border-radius: 4px;
    transition: left 0.5s ease;
}

.nSnSW, .gbnAiR{
    width: 100%;
    justify-content: center !important;
    align-items: center;
    height: 100dvh;
    backdrop-filter: blur(12px);
    position: absolute;
}

.no-scroll {
    overflow: hidden;
  }

.termsText a{
   color: inherit;
}
.termsText{
    padding-top: 1rem;
    text-align: justify;
}

.thankyouText{
    display: flex;
    justify-content: center;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 100%;
    padding: 1rem;
}

@media screen and (max-width: 768px) {

    .formBoxConatiner form{
    padding: 2rem;
    width: 90%;
}
}
@media only screen and (max-width: 600px) {
    .formBoxConatiner form{
        width: 100%;
        padding: 0;
    }

    .formBoxConatiner textarea{
        width: 90%;
    }
    .formBoxConatiner input:not([type="file" i], [type="image" i], [type="checkbox" i], [type="radio" i]) {
        width: 90%;
    }
    .AboutUsFormInputBox{
      margin-left: 1rem;
    }

    .about-btn{
        margin-left: 1rem;

    }

    .formBoxConatiner input,textarea
{
    font-size:larger;
}

.formBoxConatiner input::placeholder,textarea::placeholder{
    font-size: smaller;
}

.termsText{
    padding: 1rem;
    max-width: 88%;
    padding-bottom: 0;
}
 

}

@media only screen and (max-width: 768px) {
    .form-child{
        flex-direction: column-reverse;
        padding: 1rem;
    }

    .formBoxConatiner{
        width: 100%;
    }
    .right-from-box{
        width: 100%;
    }
    .container{
        padding: 1rem;
    }

}
.horizontal-card {
  display: flex;
  max-width: 1100px;
  width: 100%;
  border-radius: 30px;
  padding: 100px 0px 0px;
  background-image: linear-gradient(90deg, #008ee9 0, #00b693 100%);
  margin-top: 5rem;
}

.padding-h-box {
  padding: 0 1rem 0rem 1rem;
  width: 100%;
  display: flex;
  justify-content: center;
}

.horizontal-card-children {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;
}

.horizontal-card-title {
  color: white;
  max-width: 500px;
  text-align: center;
  font-weight: 800;
  font-size: xx-large;
}

.horizontalcard-container {
  width: 100%;
  display: flex;
  justify-content: center;
}

/* Responsive Screen */

@media (max-width: 768px) {
  .horizontal-card {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  .horizontal-card-title {
    padding: 0 1rem 0 1rem;
    font-size: larger;
  }
}
